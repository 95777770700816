import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { reorderArray } from '@app/util/ReorderUtils';
import TimeUtils from '@app/util/TimeUtil';
import { Ciclo, CycleBlock } from '@models/Ciclo';
import { Color } from '@models/Color';
import { UserSubject } from '@models/Subject';
import { User } from '@models/User';
import { CycleBlocksCachingService } from '@services/api/caching/cycle-blocks-caching/cycle-blocks-caching.service';
import { CycleCachingService } from '@services/api/caching/cycle-caching/cycle-caching.service';
import { UserSubjectCachingService } from '@services/api/caching/user-subject-caching/user-subject-caching.service';
import { GeneralSubjectCachingService } from '@services/api/general-subject-caching/general-subject-caching.service';
import { OfflineStorageService } from '@services/api/offline-storage/offline-storage.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { TabIdentifier, TabService } from '@services/tab-service/tab.service';
import { NetworkService } from '@services/utils/network.service';
import { Observable, Subject } from 'rxjs';
import { WeekDayScheduleView } from './wekeday-schedule-view.model';

@Injectable({
  providedIn: 'root'
})
export class WeeklyscheduleService {
  currentCycle: Ciclo;
  allBlocks: CycleBlock[] = [];
  activeBlocks: CycleBlock[] = [];
  weeklySchedule: WeekDayScheduleView[] = [
    {
      title: 'Segunda',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Terça',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Quarta',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Quinta',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Sexta',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Sábado',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    },

    {
      title: 'Domingo',
      amountTime: 0,
      formattedAmountTime: '00:00',
      expanded: true,
      items: []
    }
  ];

  private _weeklyScheduleSubject: Subject<WeekDayScheduleView[]> = new Subject<WeekDayScheduleView[]>();
  weeklyScheduleSubjectObservable: Observable<WeekDayScheduleView[]>;

  constructor(
    private offlineStorage: OfflineStorageService,
    private cycleCachingProvider: CycleCachingService,
    private cycleBlocksCachingProvider: CycleBlocksCachingService,
    private userSubjectCachingProvider: UserSubjectCachingService,
    private generalSubjectCachingProvider: GeneralSubjectCachingService,
    private userStoreService: UserStoreService,
    private networkProvider: NetworkService,
    private tabService: TabService
  ) {
    this.weeklyScheduleSubjectObservable = this._weeklyScheduleSubject.pipe();
  }

  private generateWeeklyScheduleFromActiveBlocks() {
    const d = { MONDAY: [], TUESDAY: [], WEDNESDAY: [], THURSDAY: [], FRIDAY: [], SATURDAY: [], SUNDAY: [] };
    this.activeBlocks
      .filter((block) => !block.hasPendingDelete && block.studyPlanGroupConfig)
      .forEach((block) => {
        const key: string = block.studyPlanGroupConfig.weekDay;
        d[key].push(block);
      });
    Object.keys(d).forEach((key: string, index: number) => {
      this.weeklySchedule[index].items = d[key].sort(
        (a, b) => a.studyPlanGroupConfig.order - b.studyPlanGroupConfig.order
      );
      this.weeklySchedule[index].amountTime = this.weeklySchedule[index].items
        .map((i) => i.y)
        .reduce((acc, cur) => acc + cur, 0);
      this.weeklySchedule[index].formattedAmountTime = TimeUtils.secondsToHHMM(this.weeklySchedule[index].amountTime);
    });

    this._weeklyScheduleSubject.next(this.weeklySchedule);
  }

  discardChanges() {
    this.loadCycleByUser(this.userStoreService.user, true);
  }

  refreshList(removeUnexistingBlock = false) {
    if (removeUnexistingBlock)
      this.activeBlocks = this.activeBlocks.filter((block) => block.ciclo && (block.objectId || block.localObjectId));
    this.generateWeeklyScheduleFromActiveBlocks();
  }

  async getNextSchedule() {
    const schedules = (await this.offlineStorage.get<any>('nextSchedule')) || {};
    return schedules[this.currentCycle.objectId];
  }

  async scheduleNextResetQuestion() {
    const today = new Date();
    const schedules = (await this.offlineStorage.get<any>('nextSchedule')) || {};
    today.setUTCHours(0, 0, 0, 0);
    const addDays = 7 - today.getDay();
    const nextSchedule = new Date(today.getTime() + addDays * 24 * 60 * 60 * 1000);
    schedules[this.currentCycle.objectId] = nextSchedule;
    await this.offlineStorage.set<any>('nextSchedule', schedules);
  }

  updateStatus(dayIndex: number, itemIndex: number, changedBlock: CycleBlock) {
    const block = this.weeklySchedule[dayIndex].items[itemIndex];
    block.status = changedBlock.status;
    block.hasPendingWrite = true;
  }

  updateTime(dayIndex: number, itemIndex: number, changedBlock: CycleBlock) {
    const block = this.weeklySchedule[dayIndex].items[itemIndex];
    block.tempo = changedBlock.tempo;
    block.y = changedBlock.y;
    block.total = changedBlock.total;
    block.content = changedBlock.content;
    block.status = changedBlock.status;
    block.hasPendingWrite = true;
  }

  clearReferences() {
    this.weeklySchedule.forEach((weekDaySchedule) => {
      weekDaySchedule.items = [];
      weekDaySchedule.amountTime = 0;
      weekDaySchedule.formattedAmountTime = '00:00';
    });
    this.activeBlocks = [];
    this.allBlocks = [];
  }

  async loadCycleByUser(user: User, forceReload = false) {
    this.allBlocks = this.allBlocks.concat(this.activeBlocks.slice());
    const [cycles, allBlocks] = await Promise.all([
      this.cycleCachingProvider.read(),
      this.allBlocks.length === 0 || forceReload ? this.cycleBlocksCachingProvider.read() : Promise.resolve([])
    ]);
    if (allBlocks.length > 0) this.allBlocks = allBlocks;
    this.currentCycle = cycles.find((cycle) => user.cicloAtivo && cycle.objectId === user.cicloAtivo.objectId);
    await this.offlineStorage.set<string>('ciclo', this.currentCycle.guid);
    this.extractCurrentBlocksFromAll(this.currentCycle);
    this.generateWeeklyScheduleFromActiveBlocks();
    this.updateColorOfBlocks();
    this.tabService.tabParams.set(TabIdentifier.CICLO, this.activeBlocks);
  }

  updateColorOfBlocks() {
    this.updateBlocksSubjectColor().then(() => this.generateWeeklyScheduleFromActiveBlocks());
  }

  private async updateBlocksSubjectColor() {
    const subjectIds = this.activeBlocks
      .filter((block) => block.disciplina_usuario && block.disciplina_usuario.objectId)
      .map((block) => block.disciplina_usuario.objectId);
    const subjectLocalIds = this.activeBlocks
      .filter((block) => block.disciplina_usuario && block.disciplina_usuario.localObjectId)
      .map((block) => block.disciplina_usuario.localObjectId);

    const subjects = (await this.userSubjectCachingProvider.read()).filter(
      (subject) => subjectIds.includes(subject.objectId) || subjectLocalIds.includes(subject.localObjectId)
    );

    for (const block of this.activeBlocks) {
      if (block.disciplina_usuario && subjectIds.includes(block.disciplina_usuario.objectId)) {
        const newSubject = subjects.find((subject) => {
          if (subject.objectId) return subject.objectId === block.disciplina_usuario.objectId;
          return subject.localObjectId === block.disciplina_usuario.localObjectId;
        });
        if (newSubject) block.disciplina_usuario.cor = newSubject.cor;
      }
    }
  }

  private extractCurrentBlocksFromAll(cicloAtivo: Ciclo) {
    this.activeBlocks = [];
    this.allBlocks.forEach((cycleBlock, index) => {
      cycleBlock.tempo = TimeUtils.secondsToHHMM(cycleBlock.y);
      if (cycleBlock.ciclo.objectId === cicloAtivo.objectId) {
        this.activeBlocks.push({ ...cycleBlock });
        this.allBlocks[index] = null;
      }
    });

    this.allBlocks = this.allBlocks.filter((block) => block);
    this.activeBlocks.sort((a, b) => a.order - b.order);
  }

  updateAffectedBlock(updatedBlock: CycleBlock) {
    const blockRef = this.activeBlocks.find((block) => {
      if (block.objectId && updatedBlock.objectId) return block.objectId === updatedBlock.objectId;
      if (block.localObjectId && updatedBlock.localObjectId) return block.localObjectId === updatedBlock.localObjectId;
    });

    if (blockRef) {
      blockRef.status = updatedBlock.status;
      blockRef.content = updatedBlock.content;
      blockRef.total = 100;
      blockRef.tempo_estudado = updatedBlock.tempo_estudado;
    }
  }

  async updateCycleEdit(cycleName?: string) {
    this.currentCycle.nome = cycleName || this.currentCycle.nome;
    const obj = {
      ciclo: {
        ciclo: '',
        guid: this.currentCycle.guid,
        id: this.currentCycle.objectId,
        tempociclo: this.currentCycle.tempoTotalCiclo
      }
    };

    await this.updateCycle(obj, this.currentCycle);
    this.saveWeeklySchedule();
  }

  private async updateCycle(obj: any, cycle: Ciclo) {
    const updateCiclo = obj.ciclo;
    if (updateCiclo.tempociclo) cycle.tempociclo = updateCiclo.tempociclo;
    if (typeof obj.reset === 'undefined' || obj.reset === false)
      cycle.tempoTotalCicloAtivo += updateCiclo.tempoTotalCicloAtivo || 0;
    else {
      cycle.tempoTotalCicloAtivo = 0;
      cycle.cycle_count += 1;
    }

    cycle.nome = this.currentCycle.nome;
    await this.cycleCachingProvider.update(cycle);
  }
  async reset() {
    const obj = {
      ciclo: {
        ciclo: '',
        tempoTotalCicloAtivo: 0,
        guid: this.currentCycle.guid,
        id: this.currentCycle.objectId
      },
      reset: true // indica se é para resetar o ciclo
    };

    await this.updateCycle(obj, this.currentCycle);
    this.activeBlocks.forEach((block) => {
      block.status = 'planejado';
      block.content = 0;
      block.tempo_estudado = 0;
      block.barra_progresso = {
        total: 100,
        content: 0,
        TempoTotalEstudado: 0
      };

      block.hasPendingWrite = true;
    });
    await this.saveWeeklySchedule();
    this.generateWeeklyScheduleFromActiveBlocks();
  }

  private async doBackgroundSync() {
    if (this.networkProvider.isConnected()) {
      try {
        await this.userSubjectCachingProvider.sync();
        this.cycleBlocksCachingProvider.sync();
      } catch (e) {
        console.log(
          'Sync background eror: Erro ao sincronizar blocos' + JSON.stringify(e, Object.getOwnPropertyNames(e))
        );
      }
    }
  }

  async saveWeeklySchedule() {
    await this.updateCycleBlocks({});
    this.doBackgroundSync();
  }

  addActiveBlock(block: CycleBlock) {
    this.activeBlocks.push(block);
  }

  reorderItems(dayIndex: number, indexes) {
    this.weeklySchedule[dayIndex].items = reorderArray(this.weeklySchedule[dayIndex].items, indexes);
    this.weeklySchedule[dayIndex].items.forEach((item, index) => {
      item.hasPendingWrite = true;
      item.studyPlanGroupConfig.order = index;
    });
  }

  deleteBlock(dayIndex: number, itemIndex: number) {
    const block = this.weeklySchedule[dayIndex].items[itemIndex];
    block.hasPendingDelete = true;
  }

  private async updateCycleBlocks(obj: any) {
    for (const block of this.activeBlocks) {
      const blockAlreadyExists: boolean = !!block.objectId || !!block.localObjectId;

      block.ciclo = this.currentCycle;
      if (!blockAlreadyExists) await this.createBlock(block);
      if (block.hasPendingWrite && blockAlreadyExists) this.updateBlock(block);
      if (obj.reset) this.resetBlock(block);
    }

    const blocksToSave = this.allBlocks.concat(this.activeBlocks);
    await this.cycleBlocksCachingProvider.set(blocksToSave.slice());
    this.generateWeeklyScheduleFromActiveBlocks();
  }

  private updateBlock(block: CycleBlock) {
    delete block.updatedLocally;
    block.hasPendingWrite = true;
  }

  private async createBlock(block: CycleBlock) {
    const userSubject = await this._createUserSubjectIfNotExists(
      block.disciplina_usuario.objectId,
      block.disciplina_usuario.localObjectId,
      block.disciplina_usuario.descricao,
      block.disciplina_usuario.cor
    );

    block.content = 0;
    block.localObjectId = Guid.generateLocalObjectId();
    block.createdAt = new Date();
    block.updatedAt = new Date();
    block.disciplina_usuario = userSubject;
    block.hasPendingWrite = true;
  }

  private resetBlock(block: CycleBlock) {
    block.content = 0;
    block.tempo_estudado = 0;
    block.status = 'planejado';
    block.hasPendingWrite = true;
  }

  private async _createUserSubjectIfNotExists(
    objectIdDisciplina: string,
    localObjectId: string,
    disciplina: string,
    color: Color
  ): Promise<UserSubject> {
    const subject: UserSubject | null = await this.userSubjectCachingProvider.findUserSubject({
      localObjectId: localObjectId,
      descricaoBusca: disciplina
    });

    if (subject) {
      subject.excluida = false;
      subject.cor = color;
      await this.userSubjectCachingProvider.update(subject);
      return subject;
    } else {
      const generalSubject = await this.generalSubjectCachingProvider.findGeneralSubject({
        objectId: objectIdDisciplina,
        descricaoBusca: disciplina
      });

      if (generalSubject) {
        // create a local subject from general
        const newUserSubject = await this.userSubjectCachingProvider.createFromGeneralSubject(generalSubject, color);
        return newUserSubject;
      }
    }
    return null;
  }
}
