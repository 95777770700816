import { Router } from '@angular/router';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { StorageService } from '@services/storage/storage.service';

export function initAppFactory(
  storageService: StorageService,
  userStoreService: UserStoreService
): () => Promise<any> {
  return async () => {

    await storageService.init();
    //await migrationService.execute().catch((e) => {});
    await userStoreService.init();
    
    return Promise.resolve(true);
  };
}
