import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  fMessaging: any;

  constructor() {
    this.fMessaging = (<any>window).cordova?.plugins?.firebase.messaging;
  }

  async tryRequestPermission() {
    if (this.fMessaging) {
      this.fMessaging.requestPermission({ forceShow: true }).catch((e) => {
        console.log('erro request permission');
        console.log(e);
      });
    }
  }

  async requestPermission() {
      return this.fMessaging.requestPermission({ forceShow: true });
  } 

  async getToken() {
    if (this.fMessaging) {
      this.fMessaging
        .getToken()
        .catch((e) => {
          console.log('erro token');
          console.log(e);
        });
    }
  }
}
